import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Tag {
  name: string;
  description: string;
  fields: string;
}

@Module
export default class TagsModule extends VuexModule {
  tags = {} as Tag;
  tagsMap = {} as Tag;

  /**
   * Get current tags as array
   * @returns Tag
   */
  get getTags(): Tag {
    return this.tags;
  }

  /**
   * Get current tags as id map
   * @returns Tag
   */
  get getTagsMap() : Tag {
    return this.tagsMap;
  }

  @Mutation
  [Mutations.SET_TAGS](tags) {
    this.tags = tags;
    for (let i = 0; i < tags.length; i++) {
      this.tagsMap[tags[i].id] = tags[i];
    }
  }

  @Action
  [Actions.LOAD_TAGS](language) {
    return ApiService.get("api", "tags?lang=" + language).then(({ data }) => {
      this.context.commit(Mutations.SET_TAGS, data);
    });
  }
}
