import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/",
        name: "main",
        component: () => import("@/views/Main.vue"),
      },
      {
        path: "/request-mod",
        name: "request-mod",
        component: () => import("@/views/RequestMod.vue"),
      },
      {
        path: "/mods",
        name: "mods",
        component: () => import("@/views/Mods.vue"),
      },
      {
        path: "/author/:id",
        name: "author",
        component: () => import("@/views/Author.vue"),
      },
      {
        path: "/article/:id",
        name: "article",
        component: () => import("@/views/Article.vue"),
      },
      {
        path: "/mod/:id",
        name: "mod",
        component: () => import("@/views/Mod.vue"),
      },
      {
        path: "/login",
        name: "login",
        component: () => import("@/views/admin/Login.vue"),
      },
      {
        path: "/admin-dashboard",
        name: "admin-dashboard",
        component: () => import("@/views/admin/AdminDashboard.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin-dashboard/tags",
        name: "admin-tags",
        component: () => import("@/views/admin/Tags.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin-dashboard/users",
        name: "admin-users",
        component: () => import("@/views/admin/Users.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin-dashboard/user/:id?",
        name: "admin-user",
        component: () => import("@/views/admin/User.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin-dashboard/searches",
        name: "admin-searches",
        component: () => import("@/views/admin/Searches.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin-dashboard/search/:id?",
        name: "admin-search",
        component: () => import("@/views/admin/Search.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin-dashboard/mod-request/:id?",
        name: "mod-request",
        component: () => import("@/views/admin/ModRequest.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin-dashboard/mod-requests",
        name: "mod-requests",
        component: () => import("@/views/admin/ModRequests.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin-dashboard/mod-translation/:id?",
        name: "mod-translation",
        component: () => import("@/views/admin/ModTranslation.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin-dashboard/mod-translations",
        name: "mod-translations",
        component: () => import("@/views/admin/ModTranslations.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin-dashboard/tag/:id?",
        name: "tag",
        component: () => import("@/views/admin/Tag.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin-dashboard/properties",
        name: "properties",
        component: () => import("@/views/admin/Properties.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin-dashboard/property/:id?",
        name: "property",
        component: () => import("@/views/admin/Property.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin-dashboard/articles",
        name: "admin-articles",
        component: () => import("@/views/admin/Articles.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin-dashboard/article/:id?",
        name: "admin-article",
        component: () => import("@/views/admin/Article.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin-dashboard/mods",
        name: "admin-mods",
        component: () => import("@/views/admin/Mods.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin-dashboard/mod/:id?",
        name: "admin-mod",
        component: () => import("@/views/admin/Mod.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin-dashboard/authors",
        name: "admin-authors",
        component: () => import("@/views/admin/Authors.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin-dashboard/author/:id?",
        name: "admin-author",
        component: () => import("@/views/admin/Author.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(), // createWebHashHistory
  routes,
});

function userIsAuthenticated() {
  return !!localStorage.getItem("id_token");
}

/*router.afterEach(() => {
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});*/

router.beforeEach((to, from, next) => {
  // reset config to initial state
  //store.commit(Mutations.RESET_LAYOUT_CONFIG);
  store.dispatch(Actions.VERIFY_AUTH, { token: JwtService.getToken() });
  if (to.name != from.name) {
    document.title = "Gro Repository";
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!userIsAuthenticated()) {
      next({ path: "/login", query: { redirect: to.fullPath } });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
