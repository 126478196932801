import { createI18n } from "vue-i18n";

const messages = {
  ru: {
    authors: "Авторы",
    articles: "Статьи",
    "menu-mods": "Моды",
    "mod-translations": "Запросы на перевод",
    "mod-requests": "Запросы на модификацию",
    administering: "Администрирование",
    tags: "Теги",
    "admin-dashboard": "Админ-панель",
    "request-mod": "Прислать мод",
    main: "Главная",
    mods: "Каталог",
    author: "Пример автора",
    mod: "Пример мода",
    singlePlayer: "Одиночная игра",
    cooperative: "Только кооперативная игра",
    singleAndCoop: "Одиночная и кооперативная",
    deathmatch: "Deathmatch",
    allGameModes: "Поддержка всех режимов",

    version: "Версия",

    mod_type_map: "Карта",
    mod_type_mod: "Модификация",
    mod_type_skin: "Скин игрока",
    mod_type_resource: "Ресурс",
    mod_type_dll: ".dll файлы",

    mod_request_name: "Название",
    mod_request_description: "Описание",
    mod_request_link: "Ссылка на скачивание материала",
    mod_request_screenshot_link: "Ссылка на скачивание архива со скриншотами (широкоэкранные .jpg)",
    mod_request_submit: "Отправить",
    please_wait: "Пожалуйста, подождите...",

    mod_type_0_0: "Карта для одиночной игры",
    mod_type_0_1: "Карта для кооперативной игры",
    mod_type_0_2: "Карта для одиночной и кооп. игры",
    mod_type_0_3: "Deathmatch",
    mod_type_0_4: "Карта для всех режимов игры",

    mod_type_1_0: "Мод для одиночной игры",
    mod_type_1_1: "Мод для кооперативной игры",
    mod_type_1_2: "Мод для одиночной и кооп. игры",
    mod_type_1_3: "Мод для режима Deathmatch",
    mod_type_1_4: "Мод для всех режимов игры",

    mod_type_2_0: "Скин игрока",
    mod_type_2_1: "Скин игрока",
    mod_type_2_2: "Скин игрока",
    mod_type_2_3: "Скин игрока",
    mod_type_2_4: "Скин игрока",

    mod_type_3_0: "Ресурсы",
    mod_type_3_1: "Ресурсы",
    mod_type_3_2: "Ресурсы",
    mod_type_3_3: "Ресурсы",
    mod_type_3_4: "Ресурсы",

    mod_type_4_0: ".dll файлы",
    mod_type_4_1: ".dll файлы",
    mod_type_4_2: ".dll файлы",
    mod_type_4_3: ".dll файлы",
    mod_type_4_4: ".dll файлы",

    mod_request_name_is_required: "Требуется название материала",

    oops_no_translation: "Oops! Seems there's no description for this mod in your language. If you want to provide a description translation, please press the button below, or change the language.",
    provide_english: "Предложить перевод описания",
    grorep_desktop_desc: "Автоматическая установка модов и уровней в директорию с игрой, запуск уровней прямо из приложения.",

    searchByModName: "Поиск по названию, автору...",
    gameTitle: "Игра",
    materialTypeTitle: "Тип материала",
    gameModeTitle: "Режимы игры",
    releaseDate: "Дата релиза",
    tagsTitle: "Теги",
    versionTitle: "Версия",
    download: "Скачать",
    read_more: "Читать далее",

    ru: "Русский",
    en: "Английский",
    fr: "Французский",

    video_tag_not_supported: "Ваш браузер не поддерживает видео тег.",
    modmaker: "Модмейкер",
    mapper: "Маппер",
    thatsme: "Это я!",
    change_author_information: "Изменить информацию об авторе",
    ifauthor: "Если вы - автор и хотите написать больше информации о себе и своих проектах, свяжитесь с нами. Вы можете отобразить на этой странице следующую информацию:",
    avatar: "Аватар",
    characteristic: "Небольшая характеристика, описание ваших работ",
    website: "Личный сайт",
    youtube_channel: "Youtube-канал",
    reach_us: "Напишите нам удобным способом:",
    serioussite: "SeriousSite.ru (личное сообщение)",

    about: "О сайте",
    how_to_install: "Как установить файл?",
    translation_sent: "Перевод успешно отправлен!",
    please_write_translation: "Пожалуйста, заполните перевод.",
    nothing_found: "Ничего не найдено",
    please_check_filters: "Пожалуйста, проверьте написание запроса и фильтры.",

    sort_by_release_date: "Сортировать по дате релиза",
    sort_by_publication_date: "Сортировать по дате публикации",
  },

  en: {
    authors: "Authors",
    tags: "Tags",
    administering: "Administration",
    "menu-mods": "Mods",
    "admin-dashboard": "Admin dashboard",
    "request-mod": "Send your mod",
    main: "Main",
    mods: "Repository",
    author: "Sample Author",
    mod: "Sample Mod",
    dashboard: "Dashboard",

    singlePlayer: "Singleplayer",
    cooperative: "Only co-op",
    singleAndCoop: "Single and Co-op",
    deathmatch: "Deathmatch",
    allGameModes: "All game modes",

    version: "Version",

    mod_type_map: "Map",
    mod_type_mod: "Modification",
    mod_type_skin: "Skin",
    mod_type_resource: "Resource",
    mod_type_dll: "Library files (.dll)",

    mod_request_name: "Title",
    mod_request_description: "Description",
    mod_request_link: "Download link",
    mod_request_screenshot_link: "Screenshot archive link (widescreen jpegs)",
    mod_request_submit: "Submit",
    please_wait: "Please wait...",

    mod_type_0_0: "Single player map",
    mod_type_0_1: "Cooperative map",
    mod_type_0_2: "Single & Co-op map",
    mod_type_0_3: "Deathmatch",
    mod_type_0_4: "All game modes map",

    mod_type_1_0: "Single player mod",
    mod_type_1_1: "Coop mod",
    mod_type_1_2: "Single & Coop mod",
    mod_type_1_3: "Deathmatch mod",
    mod_type_1_4: "All game modes mod",

    mod_type_2_0: "Player skin",
    mod_type_2_1: "Player skin",
    mod_type_2_2: "Player skin",
    mod_type_2_3: "Player skin",
    mod_type_2_4: "Player skin",

    mod_type_3_0: "Resources",
    mod_type_3_1: "Resources",
    mod_type_3_2: "Resources",
    mod_type_3_3: "Resources",
    mod_type_3_4: "Resources",

    mod_type_4_0: ".dll files",
    mod_type_4_1: ".dll files",
    mod_type_4_2: ".dll files",
    mod_type_4_3: ".dll files",
    mod_type_4_4: ".dll files",

    mod_request_name_is_required: "Material name is required",

    oops_no_translation: "Oops! Seems there's no description for this mod in your language. If you want to provide a description translation, please press the button below, or change the language.",
    provide_english: "Suggest a translation",
    grorep_desktop_desc: "Automatic installation of mods and levels into the game directory, launching levels directly from the application.",

    searchByModName: "Search by title, author...",
    gameTitle: "Game",
    materialTypeTitle: "Type",
    gameModeTitle: "Game modes",
    releaseDate: "Release date",
    tagsTitle: "Tags",
    versionTitle: "Version",
    download: "Download",
    read_more: "More...",

    ru: "Russian",
    en: "English",
    fr: "French",

    video_tag_not_supported: "Your browser doesn't support video tag.",
    modmaker: "Modmaker",
    mapper: "Mapper",
    thatsme: "That's me!",
    change_author_information: "Change author information",
    ifauthor: "If you are the author and want to write more information about yourself and your projects, contact us. You can display the following information on this page:",
    avatar: "Avatar",
    characteristic: "A brief characteristic, a description of your work",
    website: "Personal website",
    youtube_channel: "YouTube channel",
    reach_us: "Contact us in a convenient way:",
    serioussite: "SeriousSite.ru (private message)",

    about: "About",
    how_to_install: "How to install a file?",
    translation_sent: "Translation successfully sent!",
    please_write_translation: "Please fill in the translation.",

    nothing_found: "Nothing found",
    please_check_filters: "Please check the spelling of your query and the filters.",

    sort_by_release_date: "Sort by release date",
    sort_by_publication_date: "Sort by publication date",
  },
  fr: {
    authors: "Authors",
    administering: "Administration",
    tags: "Tags",
    "menu-mods": "Mods",
    "admin-dashboard": "Admin dashboard",
    "request-mod": "Envoyez votre mod",
    main: "Principal",
    mods: "Répertoire",
    author: "Auteur Exemple",
    mod: "Modèle Exemple",
    dashboard: "Tableau de bord",

    singlePlayer: "Solo",
    cooperative: "Jeu coopératif",
    singleAndCoop: "Solo et Coop",
    deathmatch: "Match à mort",
    allGameModes: "Tous les modes de jeu",

    version: "Version",

    mod_type_map: "Carte",
    mod_type_mod: "Modification",
    mod_type_skin: "Skin",
    mod_type_resource: "Ressource",
    mod_type_dll: "Fichiers de bibliothèque (.dll)",

    mod_request_name: "Titre",
    mod_request_description: "Description",
    mod_request_link: "Lien de téléchargement",
    mod_request_screenshot_link: "Lien d'archive des captures d'écran",
    mod_request_submit: "Soumettre",
    please_wait: "Veuillez patienter...",

    mod_type_0_0: "Carte solo",
    mod_type_0_1: "Carte coopérative",
    mod_type_0_2: "Carte solo & coop",
    mod_type_0_3: "Match à mort",
    mod_type_0_4: "Carte tous modes de jeu",

    mod_type_1_0: "Mod solo",
    mod_type_1_1: "Mod coop",
    mod_type_1_2: "Mod solo & coop",
    mod_type_1_3: "Mod match à mort",
    mod_type_1_4: "Mod tous modes de jeu",

    mod_type_2_0: "Skin de joueur",
    mod_type_2_1: "Skin de joueur",
    mod_type_2_2: "Skin de joueur",
    mod_type_2_3: "Skin de joueur",
    mod_type_2_4: "Skin de joueur",

    mod_type_3_0: "Ressources",
    mod_type_3_1: "Ressources",
    mod_type_3_2: "Ressources",
    mod_type_3_3: "Ressources",
    mod_type_3_4: "Ressources",

    mod_type_4_0: "Fichiers .dll",
    mod_type_4_1: "Fichiers .dll",
    mod_type_4_2: "Fichiers .dll",
    mod_type_4_3: "Fichiers .dll",
    mod_type_4_4: "Fichiers .dll",

    mod_request_name_is_required: "Material name is required",

    oops_no_translation: "Oups ! Il semble qu'il n'y ait pas de description pour ce mod dans votre langue. Si vous souhaitez fournir une traduction de la description, veuillez appuyer sur le bouton ci-dessous ou changer de langue.",
    provide_english: "Proposer une traduction",
    grorep_desktop_desc: "Installation automatique des mods et des niveaux dans le répertoire du jeu, lancement des niveaux directement depuis l'application.",

    searchByModName: "Recherche par titre, auteur...",
    gameTitle: "Jeu",
    materialTypeTitle: "Type",
    gameModeTitle: "Modes de jeu",
    releaseDate: "Date de sortie",
    tagsTitle: "Tags",
    versionTitle: "Version",
    download: "Télécharger",
    read_more: "Lire la suite",

    ru: "Russe",
    en: "Anglais",
    fr: "Français",

    video_tag_not_supported: "Your browser doesn't support video tag.",

    modmaker: "Créateur de mod",
    mapper: "Cartographe",
    thatsme: "C'est moi !",
    change_author_information: "Modifier les informations de l'auteur",
    ifauthor: "Si vous êtes l'auteur et souhaitez donner plus d'informations sur vous et vos projets, contactez-nous. Vous pouvez afficher les informations suivantes sur cette page :",
    avatar: "Avatar",
    characteristic: "Une petite caractéristique, une description de vos travaux",
    website: "Site personnel",
    youtube_channel: "Chaîne Youtube",
    reach_us: "Contactez-nous de la manière qui vous convient :",
    serioussite: "SeriousSite.ru (message privé)",

    about: "About",
    mod_send_success: "The mod has been sent successfully!",
    how_to_install: "Comment installer un fichier?",
    translation_sent: "Traduction envoyée avec succès!",
    please_write_translation: "Veuillez remplir la traduction.",

    nothing_found: "Rien trouvé",
    please_check_filters: "Veuillez vérifier l'orthographe de votre requête et les filtres.",

    sort_by_release_date: "Trier par date de sortie",
    sort_by_publication_date: "Trier par date de publication",
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "en",
  globalInjection: true,
  messages,
});

export default i18n;
