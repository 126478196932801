import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import VueAgile from "vue-agile";
import vSelect from "vue-select";
import Vue3Toastify from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import VueFullscreen from "vue-fullscreen";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/prismjs";
import "bootstrap";

function getLocalizedValue(jsonString, locale, noDefault) {
  try {
    let str = jsonString;
    if (str.startsWith("i18n")) {
      str = str.substr(4, str.length - 4);
    }

    const obj = JSON.parse(str);

    if (noDefault === true) {
      return obj[locale];
    }

    return obj[locale] || obj["en"];
  } catch (error) {
    console.error("Error parsing JSON:", error);
    return "Error";
  }
}

function convertToLocalizedString(obj) {
  return "i18n" + JSON.stringify(obj);
}

const app = createApp(App);

app.config.globalProperties.$globalCache = {};
app.config.globalProperties.$getLocalizedValue = getLocalizedValue;
app.config.globalProperties.$convertToLocalizedString =
  convertToLocalizedString;

app.use(Vue3Toastify, {
  autoClose: 3000,
});
app.use(vSelect);
app.use(store);
app.use(router);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
app.use(ElementPlus);
app.use(VueAgile);
app.use(VueFullscreen);

ApiService.init(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");
